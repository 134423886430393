require('intersection-observer');
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMeta from 'vue-meta';
import VueGtag from "vue-gtag";

Vue.use(VueMeta);

Vue.use(VueGtag, {
  config: { id: "UA-7613828-1" },
  enabled: process.env.NODE_ENV === 'production',
}, router);

Vue.config.productionTip = false;

new Vue({
  router,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
