<template>

  <nav class="newgrid newgrid-no-row-gap app-nav">
    <div class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-full">
      <router-link :to="{ name: 'home' }" class="tui-logo">
        <div class="tui-logo-inner" v-html="require(`!!html-loader!@/assets/svg/duck-rabbit-text.svg`)" />
        <span class="a11y-visually-hidden">Home</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AppNavigation',
  props: ['modalOpen'],
};
</script>

<style lang="scss" scoped>
.app-nav {
  padding-top: 32px;
  &>* {
    z-index: 2;
  }
}
.tui-logo {
  grid-column: 2;
  color: #0a0b09;
  outline: none;

  .dark-page & {
    color: #fff;
  }

  // Useful case study has a white duckrabbit at small and medium sizes
  .page-useful-article & {
    @media screen and (max-width: $large - 1) {
      color: #fff;
    }
  }

  // small
/*  @media screen and (max-width: $medium - 1) {
    grid-column: 1;
    &-inner {
      width: 72px;
      height: 59px;
    }
  } */

  // medium
  @media screen and (max-width: $large - 1) {
    grid-column: 1;
    &-inner {
      width: 72px;
      height: 59px;
    }
  }

  @media screen and (min-width: $large) {
    &-inner {
      width: 58px;
      height: 48px;
    }
  }
/*
  @media screen and (min-width: $extra-large) {
    width: 58px;
    height: 48px;
  } */
}

.a11y-visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal;
}
</style>
