import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: 'Home' */ "../views/Home.vue")
  },
  {
    path: "/ey",
    name: "ey-article",
    alias: "/ey-future-consumer-now",
    meta: {
      title:
        "Making connections: Bringing complex data and insight to life for EY",
      description:
        "A lean-back touch interface for visualising the drivers, personas and new worlds of the future consumer. Tui designed and built this content managed knowledge visualisation tool for Global business advisory firm EY."
    },
    component: () =>
      import(/* webpackChunkName: 'EYArticle' */ "../views/EYArticle.vue")
  },
  {
    path: "/useful",
    name: "useful-article",
    alias: "/l-and-g-useful",
    meta: {
      title: "Make it Useful: Lessons in corporate L&D",
      description:
        "We worked with financial services company Legal and General to make a tool that's focused solely on serving people in their moment of need."
    },
    component: () =>
      import(
        /* webpackChunkName: 'UsefulArticle' */ "../views/UsefulArticle.vue"
      )
  },
  {
    path: "/gsk",
    name: "gsk-article",
    meta: {
      title: "GSK: Digital onboarding for new leaders",
      description:
        "We took a typically user-centred approach to building a digital onboarding service for new joiners with leadership experience."
    },
    component: () =>
      import(/* webpackChunkName: 'UsefulArticle' */ "../views/GSKArticle.vue")
  },
  {
    path: "/*",
    redirect: {
      name: "home"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({
    x: 0,
    y: 0
  }),
  routes
});

export default router;
