<template>
  <div class="footer pw">
    <p class="footer--content slate-light">
      {{ copyright }} | <a class="footer-mailto slate-bold" :href="`mailto:${email}`">{{ email }}</a>
    </p>
    <div v-html="require(`!!html-loader!@/assets/svg/duck-rabbit.svg`)" class="icon" />
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      year: new Date().getFullYear(),
      address: '47 Greek Street, London, W1D 4EE',
      phone: 'T: 020 7734 7757',
      email: 'contact@tuimedia.com',
    };
  },
  computed: {
    copyright() {
      return `© ${this.year} Tui Media`;
    },
  },
};
</script>

<style lang="scss">
.footer {
  border-top: solid 1px #4a4a4a;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 26px;

  .dark-page & {
    color: #fff;
  }

  // small
  @media (max-width: $medium - 1) {
    padding-bottom: 0;
    flex-direction: column;
  }

  // medium
  @media (min-width: $medium) and (max-width: $large - 1) {
    padding-top: 28px;
    align-items: center;
  }

  // large
  @media (min-width: $large) and (max-width: $extra-large - 1) {
    padding-top: 40px;
    align-items: center;
  }

  @media screen and (min-width: $extra-large) {
    padding-top: 40px;
  }

  &--content {
    margin: 0 0 18px 0;
    font-size: 18px;
    line-height: 25px;
    padding-right: 70px;
    flex: 1 1 auto;

    & a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    @media screen and (min-width: $extra-large) {
      font-size: 18px;
      line-height: 25px;
    }

    @media (min-width: $large) and (max-width: $extra-large - 1){
      margin: 0 0 9px 0;
      font-size: 16px;
      line-height: 23px;
    }
    // medium
    @media (min-width: $medium) and (max-width: $large - 1) {
      margin: 0 0 11px 0;
      font-size: 13px;
      line-height: 18px;
    }
    // small
    @media (max-width: $medium - 1) {
      margin-top: 20px;
      margin-bottom: 5px;
      margin-right: 0;
      padding-right: 0;
      font-size: 14px;
      line-height: 22px;
      max-width: 100%;
    }
  }

  .icon {
    flex: 0 0 auto;
    width: 34px;
    height: 39px;


    @media screen and (min-width: $extra-large) {
      & .duck-rabbit-icon {
        transform: translateY(20px);
      }
    }

    // medium, large
    @media screen and (min-width: $medium) and (max-width: $extra-large - 1) {
      width: 28px;
      height: 32px;
    }

    // small
    @media (max-width: $medium - 1) {
      max-width: 100%;
      width: 21px;
      height: 34.6px;
      overflow: hidden; /* Cheers, IE! */

      & .duck-rabbit-icon {
        transform: translateY(10px);
      }
    }
  }
}
</style>
